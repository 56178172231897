import React from "react";
import {useState, useEffect} from "react";
import {Card} from "@nike/eds";
import {TextField} from "@nike/eds";
import {Button} from "@nike/eds";
import {IconButton} from "@nike/eds";
import {Select} from "@nike/eds";
import {RequestedSearchForm} from "../../model/RequestedSearchForm";
import useSessionStorage from "../../hooks/useSessionStorage";

const inventoryTypesOptions = [
    {value: "ADJUSTMENT", label: "Adjustment"},
    {value: "CR", label: "Customs Released"},
    {value: "DEDUCTIONS", label: "Deductions"},
    {value: "LOCK", label: "Lock / Unlock"},
    {value: "NGHIR", label: "Node Goods Holder Item Received"},
    {value: "NGR", label: "Node Goods Receipt"},
    {value: "RETURN", label: "Return Receipt"},
    {value: "SHIPMENT", label: "Shipment"},
    {value: "SUPPORT", label: "Support"},
    {value: "WAREHOUSE_ENTRY", label: "Warehouse Entry"},
    {value: "WEP", label: "Warehouse Entry Pack"},
    {value: "WEP_CR", label: "WEP - Customs Released"},
    {value: "WEP_NCTN", label: "WEP - No Customs Treatment Needed"},
    {value: "INVENTORY_SNAPSHOT_CI", label: "Inventory snapshot - CI snapshot"},
    {value: "INVENTORY_SNAPSHOT_DC", label: "Inventory snapshot - DC snapshot"},
    {value: "INVENTORY_SNAPSHOT_BALANCING", label: "Inventory balancing"}
];
const inventoryLocationOptions = [
    {value: "1060", label: "Blue Ribbon"},
    {value: "1063", label: "Samples"},
    {value: "1064", label: "Wings"},
    {value: "1065", label: "Cortez"},
    {value: "1067", label: "Windrunner"},
    {value: "1264", label: "Court"},
    {value: "IBM", label: "IBM"},
    {value: "DRS", label: "DRS"},
];
const errorCodeOptions = [
    {value: "isNull", label: "No errors"},
    {value: "isNotNull", label: "All errors"},
    {value: "INTERNAL_SERVICE_ERROR", label: "Internal service error"},
    {value: "VALIDATION_OF_INPUT_FAILED", label: "Validation of input failed"},
    {value: "ERROR_WITH_AUTHENTICATION_SERVICE", label: "Could not authenticate"},
    {value: "TIMEOUT_TO_AUTHENTICATION_SERVICE", label: "Timeout while authenticating"},
    {value: "UNKNOWN", label: "Unknown"},
];
const statusOptions = [
    {value: "isNull", label: "In Progress"},
    {value: "ERROR", label: "Error"},
    {value: "PROCESSED", label: "Processed"},
    {value: "IGNORED", label: "Ignored"},
];

const findOptionByValue = (value, options) => {
    for (const e of options) {
        if (e.value === value) {
            return e;
        }
    }
    return null;
}

const emptySearchCriteria = () => {
    return {
        source: '',
        sourceId: '',
        inventoryType: '',
        inventoryLocation: '',
        errorCode: '',
        status: '',
        startDate: '',
        endDate: '',
        businessReference: ''
    }
};

const emptyValidation = () => {
    return {
        hasErrors: false,
        startDate: '',
        endDate: ''
    };
};

function RequestSearchForm(props) {

    const [ searchCriteriaState, setSearchCriteria ] = useSessionStorage("searchCriteria", emptySearchCriteria());
    const [ validationError, setValidationError ] = useState(emptyValidation());

    const [advancedSearch, setAdvancedSearch] = useSessionStorage("advancedSearch", false);

    const searchCriteria = searchCriteriaState.value;

    const validateSearchCriteriaDates = () => {
        const errors = emptyValidation();
        if (searchCriteria.startDate !== "" && searchCriteria.endDate !== "" && (searchCriteria.startDate > searchCriteria.endDate)) {
            errors.startDate = "The start date should be before the end date";
            errors.endDate = "The end date should be after the start date";
            errors.hasErrors = true;
        }
        setValidationError(errors);
    }

    useEffect(() => {
        validateSearchCriteriaDates()
    }, [searchCriteria.startDate, searchCriteria.endDate]);


    const clearSearchCriteria = () => {
        setSearchCriteria(emptySearchCriteria());
    }
    const toggleSimpleAdvancedSearch = () => {
        setAdvancedSearch(!advancedSearch.value)
        if (!advancedSearch.value) {
            setSearchCriteria({
                ...searchCriteria,
                source: '',
                inventoryType: '',
                errorCode: '',
                status: '',
                startDate: '',
                endDate: ''
            });
        }
    }
    const sourceChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            source: event.target.value
        });
    }
    const sourceIdChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            sourceId: event.target.value
        });
    }
    const businessReferenceChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            businessReference: event.target.value
        });
    }
    const inventoryTypeChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            inventoryType: event !== null ? event.value : ''
        });
    }
    const inventoryLocationChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            inventoryLocation: event !== null ? event.value : ''
        });
    }
    const errorCodeChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            errorCode: event !== null ? event.value : ''
        });
    }
    const statusChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            status: event !== null ? event.value : ''
        });
    }
    const startDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            startDate: event.target.value
        });
    }
    const endDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            endDate: event.target.value
        });
    }

    const doSearch = (restore = false) => {
        if (validationError.hasErrors) {
            return;
        }

        let searchRequestData: RequestedSearchForm = {
            inventoryLocation: searchCriteria.inventoryLocation,
            sourceId: searchCriteria.sourceId,
        }

        if (advancedSearch.value) {
            searchRequestData = {
                ...searchRequestData,
                inventoryType: searchCriteria.inventoryType,
                errorCode: searchCriteria.errorCode,
                startDate: new Date(searchCriteria.startDate),
                endDate: new Date(searchCriteria.endDate),
                status: searchCriteria.status,
                source: searchCriteria.source,
                businessReference: searchCriteria.businessReference,
            }
        }
        props.onSearch(searchRequestData, restore);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        doSearch();
    }

    useEffect(() => {
        if (searchCriteriaState.restored) {
            doSearch(true);
        }
    }, [ searchCriteriaState.restored ]);


    return (
        <div style={{backgroundColor: "white"}}>
            <form onSubmit={submitHandler}>
                <div className="eds-grid eds-grid--m-cols-6 eds-gap--8 eds-elevation--4">
                    <Card className="eds-grid--m-col-1 eds-grid--m-rows-5 eds-grid--item-align-start eds-spacing--p-4">

                        <Card className="eds-grid--m-row-1 eds-spacing--p-4" hidden={advancedSearch.value}>
                            <div style={{"display": "flex"}}>
                            <div style={{width: 300}}>
                                <Select onChange={inventoryLocationChangeHandler}
                                        value={findOptionByValue(searchCriteria.inventoryLocation, inventoryLocationOptions)}
                                        id="inventoryLocationSelect"
                                        options={inventoryLocationOptions}
                                        label="Inventory Location"
                                        placeholder="Select inventory location..."
                                        errorMessage="Please select a source"
                                        isClearable={true}
                                        hideLabel={true}
                                />
                            </div>
                            <TextField className="eds-spacing--pl-8"
                                       onChange={sourceIdChangeHandler}
                                       id="sourceIdSearchField"
                                       type="text"
                                       hasErrors={false}
                                       label="Source Id"
                                       hideLabel={true}
                                       placeholder="Source Id"
                                       value={searchCriteria.sourceId}
                                       style={{width: 300, height: 54}}
                            />
                            </div>
                        </Card>

                        <Card className="eds-grid--m-row-1 eds-spacing--p-4" hidden={!advancedSearch.value}>
                            <TextField onChange={sourceIdChangeHandler}
                                       id="sourceIdSearchField"
                                       type="text"
                                       hasErrors={false}
                                       label="Source Id"
                                       hideLabel={!advancedSearch.value}
                                       placeholder={advancedSearch.value ? "" : "Source Id"}
                                       value={searchCriteria.sourceId}
                                       style={{width: 300}}
                            />
                        </Card>
                        <Card className="eds-grid--s-row-1 eds-spacing--p-4" hidden={!advancedSearch.value}>
                            <div style={{width: 300}}>
                                <Select onChange={inventoryLocationChangeHandler}
                                        value={findOptionByValue(searchCriteria.inventoryLocation, inventoryLocationOptions)}
                                        isClearable={true}
                                        id="inventoryLocationSelect"
                                        options={inventoryLocationOptions}
                                        errorMessage="Please select a inventory location"
                                        label="Inventory Location"
                                />
                            </div>
                        </Card>
                        <Card className="eds-grid--s-row-1 eds-spacing--p-4" hidden={!advancedSearch.value}>
                            <div style={{width: 300}}>
                                <TextField onChange={sourceChangeHandler}
                                           id="sourceSearchField"
                                           type="text"
                                           hasErrors={false}
                                           label="Source"
                                           hideLabel={!advancedSearch.value}
                                           placeholder={advancedSearch.value ? "" : "Source"}
                                           value={searchCriteria.source}
                                           style={{width: 300}}
                                />
                            </div>
                        </Card>
                    </Card>    
                    <Card className="eds-grid--m-col-1 eds-grid--m-rows-5 eds-grid--item-align-start eds-spacing--p-4">
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4" hidden={!advancedSearch.value}>
                            <div style={{width: 300}}>
                                <Select onChange={inventoryTypeChangeHandler}
                                        value={findOptionByValue(searchCriteria.inventoryType, inventoryTypesOptions)}
                                        isClearable={true}
                                        id="inventoryTypeSelect"
                                        options={inventoryTypesOptions}
                                        label="Inventory type"
                                />
                            </div>
                        </Card>
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{width: 300}}
                                 hidden={!advancedSearch.value}>
                                <Select onChange={errorCodeChangeHandler}
                                        value={findOptionByValue(searchCriteria.errorCode, errorCodeOptions)}
                                        isClearable={true}
                                        id="errorCodeSelect"
                                        label="Error code"
                                        options={errorCodeOptions}
                                />
                            </div>
                        </Card>
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{width: 300}}
                                 hidden={!advancedSearch.value}>
                                <Select onChange={statusChangeHandler}
                                        value={findOptionByValue(searchCriteria.status, statusOptions)}
                                        isClearable={true}
                                        id="statusSelect"
                                        label="Status"
                                        options={statusOptions}
                                />
                            </div>
                        </Card>
                    </Card>
                    <Card className="eds-grid--m-col-1 eds-grid--m-rows-1 eds-grid--item-align-start eds-spacing--p-4">
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{width: 300}}
                                 hidden={!advancedSearch.value}>
                                <TextField
                                    onChange={startDateChangeHandler}
                                    value={searchCriteria.startDate}
                                    id="id"
                                    type="datetime-local"
                                    hasErrors={validationError.startDate !== ''}
                                    errorMessage={validationError.startDate}
                                    label="Enter a start date"
                                />
                            </div>
                        </Card>
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{width: 300}}
                                 hidden={!advancedSearch.value}>
                                <TextField
                                    onChange={endDateChangeHandler}
                                    value={searchCriteria.endDate}
                                    id="id"
                                    type="datetime-local"
                                     hasErrors={validationError.endDate !== ''}
                                    errorMessage={validationError.endDate}
                                    label="Enter an end date"
                                />
                            </div>
                        </Card>
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4" hidden={!advancedSearch.value}>
                            <TextField onChange={businessReferenceChangeHandler}
                                       id="businessReferenceSearchField"
                                       type="text"
                                       hasErrors={false}
                                       label="Business Reference"
                                       hideLabel={!advancedSearch.value}
                                       placeholder={advancedSearch.value ? "" : "Business Reference"}
                                       value={searchCriteria.businessReference}
                                       style={{width: 300}}
                            />
                        </Card>
                    </Card>                  
                    <Card className="eds-grid--m-col-3 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end eds-spacing--p-12"
                        style={{"display": "flex"}}>
                        <Card className="eds-spacing--p-4">
                            <IconButton
                                variant="primary"
                                type={"submit"}
                                size={"small"}
                                icon="Search"
                                label={""}/>
                        </Card>
                        <Card className="eds-spacing--p-4">
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Undo"
                                size={"small"}
                            label={""}/>
                        </Card>
                        <Card className="eds-spacing--p-4">
                            <Button
                                onClick={toggleSimpleAdvancedSearch}
                                variant="primary"
                                size={"small"}
                            >{!advancedSearch.value ? 'Advanced Search' : 'Simple Search'}
                            </Button>
                        </Card>
                    </Card>
                </div>
            </form>
        </div>
    );
}

export {RequestSearchForm}
